<template>
    <div>
        <material-card class="mx-4"
                       :title="get_title"
                       text="Invoice / Quote data for factories and customers">

            <template v-if="!!order" :slot="'header_actions'">
                <v-btn small fab class="ml-2"
                       @click="download_factory_pi()">
                    <v-icon>mdi-download</v-icon>
                </v-btn>

                <v-btn small fab class="ml-2"
                       @click="load_order">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>

                <v-btn small fab class="ml-2"
                       @click="chat_opened = !chat_opened">
                    <v-icon>mdi-message</v-icon>
                </v-btn>

                <v-btn small fab
                       class="outline_btn stage_button ml-2"
                       :class="'stage_btn-' + get_stage">
                    {{ get_stage }}
                </v-btn>
            </template>

            <v-container v-if="typeof order !== 'undefined'">

                <v-row class="title">Items</v-row>

                <v-row style="max-width: 100%; overflow: auto;">
                    <v-simple-table dense>
                        <thead>
                            <tr>
                                <th class="text-left" style="min-width: 100px">Item Number</th>
                                <th class="text-left" style="min-width: 100px">Img</th>
                                <th class="text-left" style="min-width: 80px">Name</th>
                                <th class="text-center" style="min-width: 70px">CTN QTY</th>
                                <th class="text-center" style="min-width: 200px">Item Size</th>
                                <th class="text-center" style="min-width: 200px">Carton Size</th>
                                <th class="text-center">Item CBM</th>
                                <th class="text-center">Carton CBM</th>
                                <th class="text-center">Ttl Item CBM</th>
                                <th class="text-center">Ttl CTN CBM</th>
                                <th class="text-center">QTY</th>
                                <th class="text-center">Factory FOB</th>
                                <th class="text-center">Target Price</th>
                                <th class="text-center">Factory Total</th>
                                <th class="text-center" style="min-width: 200px">Remarks</th>
                            </tr>
                        </thead>

                        <tbody>
                        <tr v-for="line in order.orderlines" :key="line.id">
                            <td>{{line.product.item_number }}</td>
                            <td>
                                <div style="max-width:150px;">
                                    <v-img
                                            v-if="line.product.album && line.product.album.files.length"
                                            class="white--text"
                                            max-width="150px"
                                            :src="line.product.album.files[0].url"
                                    >
                                    </v-img>
                                </div>
                            </td>
                            <td> {{ line.product.name }} </td>
                            <td>
                                <v-text-field
                                        v-model="line.pieces_per_master_carton"
                                        type="number"
                                        :disabled="true"
                                />
                            </td>
                            <td>
                                <v-row>
                                    <v-col class="pa-1">
                                        <v-text-field
                                            style="max-width: 50px"
                                            v-model="line.product.item_size_width"
                                            type="number"
                                            label="Width"
                                    />
                                    </v-col>
                                    <v-col class="pa-1">
                                        <v-text-field
                                                style="max-width: 50px"
                                                v-model="line.product.item_size_height"
                                                type="number"
                                                label="Height"
                                        />
                                    </v-col>
                                    <v-col class="pa-1">
                                        <v-text-field
                                                style="max-width: 50px"
                                                v-model="line.product.item_size_depth"
                                                type="number"
                                                label="Depth"
                                        />
                                    </v-col>
                                </v-row>

                            </td>
                            <th>
                                <v-row>
                                    <v-col class="pa-1">
                                        <v-text-field
                                                style="max-width: 50px"
                                                v-model="line.carton_measures_width"
                                                type="number"
                                                label="Width"
                                        />
                                    </v-col>
                                    <v-col class="pa-1">
                                        <v-text-field
                                                style="max-width: 50px"
                                                v-model="line.carton_measures_height"
                                                type="number"
                                                label="Height"
                                        />
                                    </v-col>
                                    <v-col class="pa-1">
                                        <v-text-field
                                                style="max-width: 50px"
                                                v-model="line.carton_measures_depth"
                                                type="number"
                                                label="Depth"
                                        />
                                    </v-col>
                                </v-row>
                            </th>
                            <td class="text-center">
                                {{ calc_item_cbm(line) }}
                            </td>
                            <td class="text-center">
                                {{ calc_carton_cbm(line) }}
                            </td>
                            <td class="text-center">
                                {{ calc_item_ttl(line) || 'N/A' }}
                            </td>
                            <td class="text-center">
                                {{ calc_carton_ttl(line) || 'N/A'}}
                            </td>
                            <td class="text-center">
                                {{ calc_total_master_ctn(line) || 'N/A' }}
                            </td>
                            <td class="text-center">
                                <v-text-field type="number"
                                              v-model="line.factory_fob_price"
                                        />
                            </td>
                            <td>{{customer_target_price_percentage_difference(line) || 'N/A'}}</td>
                            <td class="text-center">{{ line.factory_fob_price * line.qty | currency }}</td>
                            <td><v-textarea v-model="line.remarks" :rows="1" auto-grow/></td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </v-row>

                <v-row class="title mt-4">Additional Information</v-row>

                <v-row>
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th style="width:150px;" class="text-left">Total CTN</th>
                                    <th style="width:130px;"> {{ total_ctn }} </th>
                                </tr>

                                <tr>
                                    <th class="text-left"> Total Carton CBM </th>
                                    <th> {{ total_carton_cbm.toFixed(2) }} </th>
                                </tr>

                                <tr>
                                    <th class="text-left"> Total Item CBM </th>
                                    <th> {{ total_item_cbm.toFixed(2) }} </th>
                                </tr>

                                <tr>
                                    <th>Factory Total </th>
                                    <th>{{ factory_total | currency }}</th>
                                    <th>¥{{ (factory_total * order.exchange_rate).toFixed(2) }}</th>
                                </tr>
                            </thead>
                        </template>
                    </v-simple-table>
                </v-row>

                <v-row class="title mt-4">Order remarks</v-row>

                <v-row>
                    <v-textarea label="Order Remarks"
                                v-model="order.remarks"/>
                </v-row>

                <template v-if="allowed_access_to_final">
                    <v-row class="title mt-4">
                        Invoice Information
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                    v-model="order.shipping_port"
                                    :counter="100"
                                    label="Shipping Port"/>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field
                                    v-model="order.pi_number"
                                    :counter="100"
                                    label="PI Number"
                                    :disabled="true"/>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field
                                    :disabled="true"
                                    v-model="order.po_number"
                                    :counter="100"
                                    label="PO Number"/>
                        </v-col>
                    </v-row>
                </template>


                <v-divider/>

                <v-row>
                    <v-btn block color="primary"
                           @click="save_order()">
                        Save
                        <v-icon>mdi-save</v-icon>
                    </v-btn>
                </v-row>

            </v-container>

        </material-card>


        <div class="order-chat-wrapper" v-if="chat_opened">
            <w-order-chat :selected_invoice="order" @close_chat="chat_opened = false"/>
        </div>

    </div>
</template>

<script>
    import { saveAs } from 'file-saver'
    import { gen_order_pi } from '@/plugins/excel_pi_po_generator';

    import OrderChat from "@/components/w/OrderChat"
    import { permission_intersect, calc_item_cbm, calc_carton_cbm, calc_item_ttl, calc_carton_ttl,
        calc_total_master_ctn, calc_factory_target_fob, calc_line_department_increase,
        calc_line_department_increase_success, customer_target_price_percentage_difference}
        from "@/plugins/wellstar_functions"

    export default {
        components: { OrderChat },
        data() {
            return {
                order: undefined,
                currencies: [ '$', '¥', '€', '£'],
                email_dialog: false,
                chat_opened:false,
            }
        },
        created() {
            this.load_order();
        },
        computed: {
            allowed_access_to_final: function () {
                return this.$store.getters.user_groups.filter(value => [1, 2, 15].includes(value));
            },
            get_title: function () {
                return (this.order) ? ((this.order.stage < 5) ? 'Quote: ' : 'Product Invoice: ') + this.order.pi_number : "Product Invoide: Edition";
            },
            get_stage: function () {
                return (this.order) ? this.order.stage : 1;
            },
            total_ctn: function () {
                return (!!this.order) ? this.order.orderlines.reduce((a, line) => a + (line['pieces_per_master_carton'] || 0), 0) : 0;
            },
            total_item_cbm: function () {
                return (!!this.order) ? this.order.orderlines.reduce((a, line) => a + (this.calc_item_ttl(line) || 0), 0) : 0;
            },
            total_carton_cbm: function () {
                return (!!this.order) ? this.order.orderlines.reduce((a, line) => a + (this.calc_carton_ttl(line) || 0), 0) : 0;
            },
            client_total: function () {
                return (!!this.order) ? this.order.orderlines.reduce((a, line) => a + (parseInt(line.qty) * parseFloat(line.factory_fob_price) || 0), 0) : 0;
            },
            factory_total: function () {
                return (!!this.order) ? this.order.orderlines.reduce((a, line) => a + (parseInt(line.qty) * parseFloat(line.factory_fob_price) || 0), 0) : 0;
            }
        },
        methods: {
            async load_order() {
                let res = await this.$hget('/order/' + this.$route.params.invoice_id);

                if(!(res.status < 200 || res.status >= 300))
                    this.order = res.data;

                console.log(this.order)
            },

            async save_order(){
                let res = await this.$hpost('/order/' + this.$route.params.invoice_id + '/', {order: this.order});
                this.load_order();
            },

            async download_factory_pi(){
                let buffer = await gen_order_pi(this, this.order, this.order.orderlines, true);
                await saveAs(new Blob([buffer]), this.order.pi_number + ".xlsx");
            },

            async close_chat(){
                console.log("CLOSE CHAT");
                this.chat_opened = false;
            },

            async orderChatPopup() {
                let res = await this.$hget('/order/' + this.order.pi_number + '/');

                if(res.status < 200 || res.status >= 300){
                    this.notification = true;
                    this.notification_message = 'Error: Please contact administrator';
                    console.log("Error");
                    console.log(error);
                }else{
                    this.chat_opened = true;
                }
            },

            calc_item_cbm(line){
                return calc_item_cbm(line);
            },
            calc_carton_cbm(line){
                return calc_carton_cbm(line);
            },
            calc_item_ttl(line){
                return calc_item_ttl(line);
            },
            calc_carton_ttl(line){
                return calc_carton_ttl(line);
            },
            calc_total_master_ctn(line){
                return calc_total_master_ctn(line);
            },
            customer_target_price_percentage_difference(line) {
                return customer_target_price_percentage_difference(line);
            }
        }
    }
</script>

<style lang="scss">
    .order-chat-wrapper {
        width:400px;
        height:500px;
        position: fixed;
        bottom:0;
        right:0;
        z-index:900;
        background:white;

        .close {
            width:24px;
            height:24px;
            position: absolute;
            top:5px;
            right:5px;
            z-index:901;
        }
    }
</style>
